export type ModeType = "dark" | "light";

export type ThemeData = {
  // mode
  mode: ModeType;

  // text
  textPrimary: string;
  textLight: string;
  textDark: string;

  // default
  defaultMain: string;
  defaultLight: string;
  defaultDark: string;

  // primary
  primaryMain: string;
  primaryLight: string;
  primaryDark: string;

  // accent
  accentMain: string;
  accentLight: string;
  accentDark: string;
  accentMainHover: string;
  accentMainDisabled: string;
  accentContrastText: string;

  // error
  errorMain: string;
};

export const colorDark: ThemeData = {
  mode: "dark",
  textPrimary: "#000",
  textLight: "#D5D7DE", // text white
  textDark: "#9B9DA3",
  defaultMain: "#000",
  defaultLight: "#333647", // bg input field
  defaultDark: "#1F212C", //bg header
  primaryMain: "#000",
  primaryLight: "#1F212C",
  primaryDark: "#191B27", //bg body
  accentMain: "#5d3594", // color main (bg color)
  accentLight: "#000",
  accentDark: "#141625", // bg card
  accentMainHover: "#512e81",
  accentMainDisabled: "#000",
  accentContrastText: "#000",
  errorMain: "#EE4E4E",
};

export const colorLight: ThemeData = {
  mode: "light",
  textPrimary: "#000",
  textLight: "#000",
  textDark: "#000",
  defaultMain: "#000",
  defaultLight: "#e0daed",
  defaultDark: "#fff",
  primaryMain: "#000",
  primaryLight: "#f3f0fa",
  primaryDark: "#fff",
  accentMain: "#5d3594",
  accentLight: "#000",
  accentDark: "#e0daed",
  accentMainHover: "#512e81",
  accentMainDisabled: "#000",
  accentContrastText: "#000",
  errorMain: "#EE4E4E",
};
