import { QuestionData } from "models/classes/question.class";
import { parseRequestParams } from "utils/dataMappers";
import { publicRequest } from "../configs/requestApis";
import { FetchQuestionParams } from "./questionParam";

export const fetchQuestionListApi = async ({
  searchName,
  page,
  pageSize,
  newAbortSignal,
  ...params
}: FetchQuestionParams) =>
  publicRequest.get(
    `/question/list`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchQuestionClientApi = async ({
  searchName,
  page,
  pageSize,
  level,
  newAbortSignal,
  ...params
}: FetchQuestionParams) =>
  publicRequest.get(
    `/question/list/client/${params.interviewCategoryId}`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, level, newAbortSignal },
      true
    )
  ) as any;

export const fetchQuestionCreateApi = async (body: QuestionData) =>
  publicRequest.post(`/question/create`, body) as any;

export const fetchQuestionDetailApi = async ({
  questionId,
  newAbortSignal,
  ...params
}: FetchQuestionParams) =>
  publicRequest.get(
    `/question/detail/${questionId}`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchQuestionUpdateApi = async (
  id: number,
  body: QuestionData
) => publicRequest.put(`/question/update/${id}`, body) as any;
