import { Outlet, RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom";

// HOOKS
import { lazy, Suspense, useLayoutEffect } from 'react';

// CONTANST
import { PageRouteName } from "globals/enums/routes.enum";

// SERVICE
import { getLangCode } from "locales";
import { changeLanguage } from 'services/localeService';

// COMPONENTS
import MainLayout from 'layouts/MainLayout';
import ContactPage from "pages/ContactPage/ContactPage";
import CourseCategoryPage from "pages/CourseCategoryPage/CourseCategoryPage";
import CourseDetailPage from "pages/CourseDetailPage/CourseDetailPage";
import CourseStorePage from "pages/CourseStorePage/CourseStorePage";
import CourseViewPage from "pages/CourseViewPage/CourseViewPage";
import DashboardPage from "pages/DashboardPage/DashboardPage";
import InterviewPage from "pages/InterviewPage/InterviewPage";
import ProjectDetailPage from "pages/ProjectDetailPage/ProjectDetailPage";
import ProjectPage from "pages/ProjectPage/ProjectPage";
import SignInPage from "pages/SignInPage/SignInPage";
import SignUpPage from "pages/SignUpPage/SignUpPage";
import InterviewDetailPage from "pages/InterviewDetailPage/InterviewDetailPage";
import LogoutPage from "pages/LogoutPage/LogoutPage";
import NotFoundPage from "pages/NotFoundPage/NotFoundPage";
import ProfilePage from "pages/ProfilePage/ProfilePage";

const ProfileCategories = lazy(() => import("pages/ProfilePage/sections/ProfileCategories"));
const ProfileCategoriesCreate = lazy(() => import("pages/ProfilePage/sections/ProfileCategoriesCreate"));
const ProfileDescription = lazy(() => import("pages/ProfilePage/sections/ProfileDescription"));
const ProfileDescriptionCreate = lazy(() => import("pages/ProfilePage/sections/ProfileDescriptionCreate"));
const ProfileInformation = lazy(() => import("pages/ProfilePage/sections/ProfileInformation"));
const ProfileInterview = lazy(() => import("pages/ProfilePage/sections/ProfileInterview"));
const ProfileInterviewCreate = lazy(() => import("pages/ProfilePage/sections/ProfileInterviewCreate"));
const ProfileLesson = lazy(() => import("pages/ProfilePage/sections/ProfileLesson"));
const ProfileLessonCreate = lazy(() => import("pages/ProfilePage/sections/ProfileLessonCreate"));
const ProfilePassword = lazy(() => import("pages/ProfilePage/sections/ProfilePassword"));
const ProfilePurchased = lazy(() => import("pages/ProfilePage/sections/ProfilePurchased"));
const ProfileQuestion = lazy(() => import("pages/ProfilePage/sections/ProfileQuestion"));
const ProfileQuestionCreate = lazy(() => import("pages/ProfilePage/sections/ProfileQuestionCreate"));
const ProfileSaller = lazy(() => import("pages/ProfilePage/sections/ProfileSaller"));
const ProfileSallerCreate = lazy(() => import("pages/ProfilePage/sections/ProfileSallerCreate"));
const ProfileSection = lazy(() => import("pages/ProfilePage/sections/ProfileSection"));
const ProfileSectionCreate = lazy(() => import("pages/ProfilePage/sections/ProfileSectionCreate"));
const ProfileStudent = lazy(() => import("pages/ProfilePage/sections/ProfileStudent"));
const ProfileStudentCreate = lazy(() => import("pages/ProfilePage/sections/ProfileStudentCreate"));
const ProfileUser = lazy(() => import("pages/ProfilePage/sections/ProfileUser"));
const ProfileAnswer = lazy(() => import("pages/ProfilePage/sections/ProfileAnswer"));
const ProfileAnswerCreate = lazy(() => import("pages/ProfilePage/sections/ProfileAnswerCreate"));

const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <DashboardPage />
      },
      {
        path: PageRouteName.CourseStorePage,
        children: [
          {
            index: true,
            element: <CourseStorePage />
          },
          {
            path: PageRouteName.CourseCategoryStorePage,
            children: [
              {
                index: true,
                element: <CourseCategoryPage />
              },
              {
                path: `${PageRouteName.CourseCategoryStorePage}/:id`,
                element: <CourseDetailPage />
              },
            ]
          },
        ]
      },
      {
        path: '/projects',
        element: <ProjectPage />
      },
      {
        path: '/projects/detail/:id',
        element: <ProjectDetailPage />
      },
      {
        path: '/interview',
        element: <InterviewPage />
      },
      {
        path: '/interview/detail/:id',
        element: <InterviewDetailPage />
      },
      {
        path: '/signin',
        element: <SignInPage />
      },
      {
        path: '/signup',
        element: <SignUpPage />
      },
      {
        path: '/profile',
        element: <ProfilePage />,
        children: [
          {
            path: '/profile/information',
            element: <ProfileInformation />
          },
          {
            path: '/profile/password',
            element: <ProfilePassword />
          },
          {
            path: '/profile/purchased',
            element: <ProfilePurchased />
          },
          {
            path: '/profile/lesson',
            element: <ProfileLesson />
          },
          {
            path: '/profile/lesson/create',
            element: <ProfileLessonCreate />
          },
          {
            path: '/profile/lesson/update/:id',
            element: <ProfileLessonCreate />
          },
          {
            path: '/profile/section',
            element: <ProfileSection />
          },
          {
            path: '/profile/section/create',
            element: <ProfileSectionCreate />
          },
          {
            path: '/profile/section/update/:id',
            element: <ProfileSectionCreate />
          },
          {
            path: '/profile/description',
            element: <ProfileDescription />
          },
          {
            path: '/profile/description/create',
            element: <ProfileDescriptionCreate />
          },
          {
            path: '/profile/description/update/:id',
            element: <ProfileDescriptionCreate />
          },
          {
            path: '/profile/saller',
            element: <ProfileSaller />
          },
          {
            path: '/profile/saller/create',
            element: <ProfileSallerCreate />
          },
          {
            path: '/profile/saller/update/:id',
            element: <ProfileSallerCreate />
          },
          {
            path: '/profile/student',
            element: <ProfileStudent />
          },
          {
            path: '/profile/user',
            element: <ProfileUser />
          },
          {
            path: '/profile/categories',
            element: <ProfileCategories />
          },
          {
            path: '/profile/categories/create',
            element: <ProfileCategoriesCreate />
          },
          {
            path: '/profile/categories/update/:id',
            element: <ProfileCategoriesCreate />
          },
          {
            path: '/profile/student/create',
            element: <ProfileStudentCreate />
          },
          {
            path: '/profile/interview',
            element: <ProfileInterview />
          },
          {
            path: '/profile/interview/create',
            element: <ProfileInterviewCreate />
          },
          {
            path: '/profile/interview/update/:id',
            element: <ProfileInterviewCreate />
          },
          {
            path: '/profile/question',
            element: <ProfileQuestion />
          },
          {
            path: '/profile/question/create',
            element: <ProfileQuestionCreate />
          },
          {
            path: '/profile/question/update/:id',
            element: <ProfileQuestionCreate />
          },
          {
            path: '/profile/answer',
            element: <ProfileAnswer />
          },
          {
            path: '/profile/answer/create',
            element: <ProfileAnswerCreate />
          },
          {
            path: '/profile/answer/update/:id',
            element: <ProfileAnswerCreate />
          },
        ]
      },
      {
        path: '/courses/view',
        element: <CourseViewPage />,
      },
      {
        path: '/contact',
        element: <ContactPage />,
      },
    ],
  },
  {
    path: '/logout',
    element: <LogoutPage />
  },
  {
    path: '/*',
    element: <NotFoundPage />
  },
];

const router = createBrowserRouter([
  {
    element: (
      <Outlet />
    ),
    children: appRoutes,
  }
]);

const App = () => {
  useLayoutEffect(() => {
    if (!getLangCode()) {
      changeLanguage('vi');
    }
  }, []);

  return (
    <Suspense>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
