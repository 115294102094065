import React, { useEffect, useMemo } from "react";

import styled from "styled-components";

// HOOKS
import useRoute from "hooks/useRoute";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

// COMPONENTS
import { Box, Stack } from "@mui/material";
import ProfileNavbar from "./sections/ProfileNavbar";

// CONSTANTS
import { SessionKey, StorageKey } from "globals/constants/storage";
import { Roles } from "globals/enums/auth.enum";
import { PageRouteName } from "globals/enums/routes.enum";
import { getStorage } from "services/storageService";
import { useThemeContext } from "store/themeContext";
import { DARK_THEME, WHITE } from "globals/enums/common.enum";

type ProfileName = 'Information' | 'Purchased' | 'Manage Courses' | 'Password' |
  'Manage Lessons' | 'Manage Sections' | 'Manage Descriptions' | 'Manage Student' | 'Manage Users' | 'Manage Categories' |
  'Manage Interview' | 'Manage Question' | 'Manage Answer';

type LinkItemNav = {
  name: ProfileName;
  section: PageRouteName;
};

const ProfilePage = React.memo(() => {
  const { t } = useTranslation();
  const { redirect, pathname } = useRoute();
  const userRole = getStorage(StorageKey.Role) || Roles.STUDENT;

  const { activeTheme } = useThemeContext();

  setTimeout(() => {
    if (pathname === PageRouteName.ProfilePage) {
      redirect(PageRouteName.ProfileInformationPage);
    }
  }, 0);

  // Student
  const linkItemStudent: LinkItemNav[] = [
    {
      name: 'Information',
      section: PageRouteName.ProfileInformationPage,
    },
    {
      name: 'Password',
      section: PageRouteName.ProfilePasswordPage,
    },
    {
      name: 'Purchased',
      section: PageRouteName.ProfilePurchasedPage,
    },
  ];

  // Teacher
  const linkItemTeacher: LinkItemNav[] = [
    {
      name: 'Information',
      section: PageRouteName.ProfileInformationPage,
    },
    {
      name: 'Password',
      section: PageRouteName.ProfilePasswordPage,
    },
    {
      name: 'Manage Courses',
      section: PageRouteName.ProfileSallerPage,
    },
    {
      name: 'Manage Sections',
      section: PageRouteName.ProfileSectionPage,
    },
    {
      name: 'Manage Lessons',
      section: PageRouteName.ProfileLessonPage,
    },
    {
      name: 'Manage Descriptions',
      section: PageRouteName.ProfileDescriptionPage,
    },
    {
      name: 'Manage Student',
      section: PageRouteName.ProfileManageStudentPage,
    },
  ];

  // Admin
  const linkItemAdmin: LinkItemNav[] = [
    {
      name: 'Information',
      section: PageRouteName.ProfileInformationPage,
    },
    {
      name: 'Manage Courses',
      section: PageRouteName.ProfileSallerPage,
    },
    {
      name: 'Manage Users',
      section: PageRouteName.ProfileUserPage,
    },
    {
      name: 'Manage Categories',
      section: PageRouteName.ProfileCategoriesPage,
    },
    {
      name: 'Manage Interview',
      section: PageRouteName.ProfileInterviewPage,
    },
    {
      name: 'Manage Question',
      section: PageRouteName.ProfileQuestionPage,
    },
    {
      name: 'Manage Answer',
      section: PageRouteName.ProfileAnswerPage,
    },
  ];

  const resultItemRender = {
    [Roles.STUDENT]: linkItemStudent,
    [Roles.TEACHER]: linkItemTeacher,
    [Roles.ADMIN]: linkItemAdmin,
  };

  const linkItemRender = useMemo(() => {
    return resultItemRender[userRole].map((item: LinkItemNav, index: number) => (
      <Box
        key={index}
        className={`link-item ${pathname.includes(item.section) ? 'bg-main' : ''}`}
        padding={{ xs: '.3rem .6rem', md: '.6rem 1rem' }}
        onClick={() => {
          redirect(item.section);
        }}
        color={activeTheme == DARK_THEME ? WHITE : ''}
      >
        {t(item.name)}
      </Box>
    ))
  }, [activeTheme, pathname, localStorage.getItem(SessionKey.Language)]);

  // effects
  useEffect(() => {
    const allowRoutes: string[] = resultItemRender[userRole].map((item: LinkItemNav) => item.section);
    const isAllowRoute = allowRoutes.some(item => pathname.includes(item));
    if (!isAllowRoute) {
      redirect(PageRouteName.DashboardPage);
    }
  }, [pathname]);

  return (
    <WrapperStyled
      padding={{ xs: '3rem 2rem' }}
      width={{ xs: 'auto', md: '100%' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '3rem' }}
    >
      <ProfileNavbar linkItemRender={linkItemRender} />
      <Outlet />
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`
`;

export default ProfilePage;
