import moment from "moment";

export const formatCurrencyNumberWithDecimal = (value: string) => {
  if (value) {
    const cleanedValue = String(value).replace(/,/g, "");
    const parts = cleanedValue.split(".");
    const integerPart = parts[0].replace(/\D/g, "");
    const decimalPart = parts[1] ? parts[1].replace(/\D/g, "").slice(0, 4) : "";

    let formattedValue = "";

    if (integerPart) {
      formattedValue = parseFloat(integerPart).toLocaleString("en-US");
    }

    if (decimalPart) {
      formattedValue += `.${decimalPart}`;
    }

    return formattedValue || 0;
  }
};

export const checkWidthLessThan768 = () => {
  return window.innerWidth <= 768;
};

export const regexNumber = /^\d+$/;

export const listFileType = [
  "image/webp",
  "image/jpg",
  "image/jpeg",
  "image/png",
];

// 1 MB
const maxSizeInBytes = 1 * 1024 * 1024;

export const validationFile = (input: any) => {
  const fileType = input.type;
  return listFileType.includes(fileType);
};

export const validationSize = (input: any) => {
  const fileSize = input.size;
  return fileSize <= maxSizeInBytes;
};

export const generateTime = (date: number | string) => {
  return String(
    moment(
      new Date(date).toLocaleString("en-US", { timeZone: "Asia/Jakarta" })
    ).format("DD/MM/YYYY HH:mm:ss")
  );
};

export const generateAtTime30Day = () => {
  const startDate = new Date();
  const endDate = Number(startDate.getTime()) + 2592000000;
  return endDate.toString();
};
