import useAbortRequest from "hooks/useAbortRequest";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { FetchInterviewCategoryParams } from "./interviewCategoryParam";
import { useInterviewCategoryContext } from "./interviewCategoryContext";
import {
  actionInterviewCategoryApi,
  actionInterviewClientApi,
  actionInterviewDetailApi,
} from "./interviewCategoryAction";

export const useInterviewCategoryByApi = ({
  searchName,
  page,
  pageSize,
}: FetchInterviewCategoryParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { interviewCategoryListRes, updateInterviewList } =
    useInterviewCategoryContext();

  const fetchInterviewCategoryByApi = async () => {
    await abortRequest();
    const data = await actionInterviewCategoryApi({
      searchName,
      page,
      pageSize,
      newAbortSignal,
    });
    if (!isEmpty(data)) {
      updateInterviewList(data!);
    }
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(interviewCategoryListRes) || searchName || page) {
      fetchInterviewCategoryByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [searchName, page]);

  return {
    interviewCategoryListRes,
    fetchInterviewCategoryByApi,
  };
};

export const useInterviewClientByApi = ({
  searchName,
  page,
  pageSize,
}: FetchInterviewCategoryParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { interviewClientListRes, updateInterviewClientList } =
    useInterviewCategoryContext();

  const fetchInterviewClientByApi = async () => {
    await abortRequest();
    const data = await actionInterviewClientApi({
      searchName,
      page,
      pageSize,
      newAbortSignal,
    });
    if (!isEmpty(data)) {
      updateInterviewClientList(data!);
    }
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(interviewClientListRes) || searchName || page) {
      fetchInterviewClientByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [searchName, page]);

  return {
    interviewClientListRes,
    fetchInterviewClientByApi,
  };
};

export const useInterviewCategoryDetailByApi = ({
  interviewId,
}: FetchInterviewCategoryParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { interviewCategoryDetail, updateInterviewDetail } =
    useInterviewCategoryContext();

  const fetchInterviewCategoryDetailByApi = async () => {
    await abortRequest();
    const data = await actionInterviewDetailApi({
      interviewId,
      newAbortSignal,
    });
    updateInterviewDetail(data!);
  };

  // Unmout Apis
  useEffect(() => {
    if (interviewId) {
      fetchInterviewCategoryDetailByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [interviewId]);

  return {
    interviewCategoryDetail: interviewId ? interviewCategoryDetail : null,
    fetchInterviewCategoryDetailByApi,
  };
};
