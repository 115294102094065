
import { QuestionDto, QuestionListRes } from "models/classes/question.class";
import { PropsWithChildren, createContext, useContext, useState } from "react";

type State = {
  questionListRes: QuestionListRes | null;
  questionClientRes: QuestionListRes | null;
  questionDetail: QuestionDto | null;
};

type Actions = {
  updateQuestionList: (questionListRes: QuestionListRes) => void;
  updateQuestionClient: (questionClientRes: QuestionListRes) => void;
  updateQuestionDetail: (questionDetail: QuestionDto) => void;
};

const initState: State = {
  questionListRes: null,
  questionClientRes: null,
  questionDetail: null,
};

const defaultActions: Actions = {
  updateQuestionList: () => { },
  updateQuestionClient: () => { },
  updateQuestionDetail: () => { },
};

const QuestionContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const QuestionProvider = ({ children }: PropsWithChildren<{}>) => {
  const [questionListRes, setQuestionListRes] = useState<QuestionListRes | null>(null);
  const [questionClientRes, setQuestionClientRes] = useState<QuestionListRes | null>(null);
  const [questionDetail, setQuestionDetail] = useState<QuestionDto | null>(null);

  const updateQuestionList = (questionListRes: QuestionListRes) => {
    setQuestionListRes(questionListRes);
  };

  const updateQuestionClient = (questionListRes: QuestionListRes) => {
    setQuestionClientRes(questionListRes);
  };

  const updateQuestionDetail = (questionDetail: QuestionDto) => {
    setQuestionDetail(questionDetail);
  };

  return (
    <QuestionContext.Provider
      value={{
        questionListRes,
        updateQuestionList,

        questionDetail,
        updateQuestionDetail,

        questionClientRes,
        updateQuestionClient,
      }}
    >
      {children}
    </QuestionContext.Provider>
  );
};

export const useQuestionContext = () => useContext(QuestionContext);
