import BaseModel from "./base.class";

export class AnswerDto extends BaseModel<AnswerDto> {
  id?: number;
  description?: string;
  view?: number;
  isPublic?: boolean;
  questionId?: number;
}

export type AnswerListRes = {
  list: AnswerDto[];
  total: number;
  page: number;
  pageSize: number;
};

export type AnswerData = {
  description: string;
  view?: number;
  isPublic: boolean;
  questionId: number;
};
