
import { InterviewCategoryDto, InterviewCategoryListRes } from "models/classes/interviewCategory.class";
import { PropsWithChildren, createContext, useContext, useState } from "react";

type State = {
  interviewCategoryListRes: InterviewCategoryListRes | null;
  interviewClientListRes: InterviewCategoryListRes | null;
  interviewCategoryDetail: InterviewCategoryDto | null;
};

type Actions = {
  updateInterviewList: (interviewCategoryListRes: InterviewCategoryListRes) => void;
  updateInterviewClientList: (interviewClientListRes: InterviewCategoryListRes) => void;
  updateInterviewDetail: (interviewCategoryDetail: InterviewCategoryDto) => void;
};

const initState: State = {
  interviewCategoryListRes: null,
  interviewClientListRes: null,
  interviewCategoryDetail: null,
};

const defaultActions: Actions = {
  updateInterviewList: () => { },
  updateInterviewClientList: () => { },
  updateInterviewDetail: () => { },
};

const InterviewCategoryContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const InterviewCategoryProvider = ({ children }: PropsWithChildren<{}>) => {
  const [interviewCategoryListRes, setInterviewCategoryListRes] = useState<InterviewCategoryListRes | null>(null);
  const [interviewClientListRes, setInterviewClientListRes] = useState<InterviewCategoryListRes | null>(null);
  const [interviewCategoryDetail, setInterviewCategoryDetail] = useState<InterviewCategoryDto | null>(null);

  const updateInterviewList = (interviewCategoryListRes: InterviewCategoryListRes) => {
    setInterviewCategoryListRes(interviewCategoryListRes);
  };

  const updateInterviewClientList = (interviewClientListRes: InterviewCategoryListRes) => {
    setInterviewClientListRes(interviewClientListRes);
  };

  const updateInterviewDetail = (interviewCategoryDetail: InterviewCategoryDto) => {
    setInterviewCategoryDetail(interviewCategoryDetail);
  };

  return (
    <InterviewCategoryContext.Provider
      value={{
        interviewCategoryListRes,
        updateInterviewList,

        interviewCategoryDetail,
        updateInterviewDetail,

        interviewClientListRes,
        updateInterviewClientList,
      }}
    >
      {children}
    </InterviewCategoryContext.Provider>
  );
};

export const useInterviewCategoryContext = () => useContext(InterviewCategoryContext);
