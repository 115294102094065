import { CircularProgress, Stack } from "@mui/material";
import { DARK_THEME, PURPLE_01, WHITE } from "globals/enums/common.enum";
import { ReactNode } from "react";
import { useThemeContext } from "store/themeContext";

type Props = {
  isLoading: boolean;
  children: ReactNode;
};

const LoadingWrapper = ({
  isLoading,
  children,
}: Props) => {
  const { activeTheme } = useThemeContext();

  return isLoading ? (
    <Stack
      width='100%'
      height='100vh'
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size="4.5rem" style={{ color: activeTheme == DARK_THEME ? WHITE : PURPLE_01 }} />
    </Stack>
  ) : children
};

export default LoadingWrapper;
