import { AxiosRequestConfig } from "axios";
import { Obj } from "globals/constants/types";
import { MetaParams } from "globals/types/requests.type";

export const arrayParamToString = (arr: (number | string)[] | undefined) =>
  (arr || []).length > 0 ? (arr || []).join(",") : undefined;

export const parseMetaParams = (meta: MetaParams | undefined) => {
  const { page, pageSize } = meta || {};

  return {
    page,
    pageSize: pageSize || 1000,
  };
};

export const parseRequestParams = (
  dataParams?: Obj,
  metaParams?: MetaParams,
  fetchMultiple = false
): AxiosRequestConfig => {
  const configs: AxiosRequestConfig = { params: {}, options: {} };

  // Set query params
  if (dataParams) {
    Object.keys(dataParams).forEach((field) => {
      const value = dataParams[field];
      if (!value || value === -1) return;

      if (Array.isArray(value)) {
        configs.params[field] = arrayParamToString(value);
      } else {
        configs.params[field] = value;
      }
    });
  }

  // Set meta params
  if (fetchMultiple) {
    const { searchName, page = 1, pageSize = 10, level } = metaParams || {};
    if (searchName) {
      configs.params.name = searchName;
    }
    if (level && Number(level) !== -1) {
      configs.params.level = level;
    }

    configs.params.pageSize = pageSize;
    configs.params.page = page;
  }

  // Additional functions
  if (metaParams) {
    const { newAbortSignal } = metaParams;

    if (newAbortSignal) configs.options!.newAbortSignal = newAbortSignal;
  }

  return configs;
};
