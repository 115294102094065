import { lazy, useLayoutEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

// FUNCTION
import { checkWidthLessThan768 } from "utils/dataHelpers";

// COMPONENT
import { Box, Stack, useTheme } from "@mui/material";
import HeaderLayout from "./HeaderLayout";

// ICONS
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuthContext } from "apis/authApis/authContext";
import { StorageKey } from "globals/constants/storage";
import { Roles } from "globals/enums/auth.enum";
import useRoute from "hooks/useRoute";
import { getStorage, removeStorage } from "services/storageService";
import { WHITE } from "globals/enums/common.enum";

// LAZY
const PopupMobile = lazy(() => import("./sections/PopupMobile"));

const MainLayout = () => {
  const theme = useTheme();
  const { pathname } = useRoute();

  // ref
  let portrait = window.matchMedia("(orientation: portrait)");

  // state
  const [isMobile, setIsMobile] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  // hooks
  const { updateToken, updateRole, updateExpiredTime } = useAuthContext();

  portrait.addEventListener("change", function (e) {
    if (e.matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });

  useLayoutEffect(() => {
    if (getStorage(StorageKey.AccessToken) && getStorage(StorageKey.Role)) {
      const currentTime = Number(new Date().getTime());
      const expiredTime = Number(getStorage(StorageKey.ExpiredTime));
      if (currentTime < expiredTime) {
        updateToken(getStorage(StorageKey.AccessToken)!);
        updateRole(getStorage(StorageKey.Role)! as Roles);
        updateExpiredTime(getStorage(StorageKey.ExpiredTime)!);
      } else {
        removeStorage(StorageKey.AccessToken);
        removeStorage(StorageKey.Role);
        removeStorage(StorageKey.ExpiredTime);
      }
    }
  }, []);

  useLayoutEffect(() => {
    if (openMenu) setOpenMenu(false);
  }, [pathname]);

  useLayoutEffect(() => {
    if (checkWidthLessThan768() && pathname.includes('/profile')) {
      setIsMobile(true);
    }
  }, [pathname]);

  return (
    <WrapperMainLayout
      flexDirection={{ xs: 'row' }}
      bgcolor={{ xs: theme.palette.primary.dark }} width="100%"
    >
      {isMobile && (
        <PopupMobile />
      )}
      {!isMobile && (
        <>
          <Box display={{ xs: openMenu ? 'block' : 'none', md: 'block' }}>
            <HeaderLayout />
          </Box>
          <Stack
            position="fixed"
            bottom={40}
            right={30}
            className="bg-main"
            flexDirection={{ xs: 'row' }}
            justifyContent={{ xs: 'center' }}
            alignItems={{ xs: 'center' }}
            zIndex={1300}
            padding={{ xs: '.3rem .6rem' }}
            borderRadius={1}
            display={{ xs: 'flex', md: 'none' }}
            style={{ cursor: 'pointer' }}
            onClick={() => setOpenMenu(!openMenu)}
          >
            {openMenu
              ? <CloseIcon style={{ color: WHITE }} fontSize="large" />
              : <MenuIcon style={{ color: WHITE }} fontSize="large" />
            }
          </Stack>
          {openMenu && (
            <Box
              position="fixed"
              width="100%"
              height={{ xs: '100%' }}
              zIndex={1299}
              bgcolor={{ xs: theme.palette.primary.dark }}
              style={{ opacity: '.8' }}
            ></Box>
          )}
          <Box
            width={{ xs: '100%', md: '75%' }}
            marginLeft={{ xs: 0, md: '20%', xl: '18%' }}
          >
            <Outlet />
          </Box>
        </>
      )}
    </WrapperMainLayout>
  )
};

const WrapperMainLayout = styled(Stack)`
  
`;

export default MainLayout;
