import { CoursePersonalDetailDto, CoursePersonalListRes, CoursePersonalNewsDto, CoursePersonalVipsDto } from "models/classes/coursePersonal.class";
import { createContext, PropsWithChildren, useContext, useState } from "react";

type State = {
  coursePersonalNews: CoursePersonalNewsDto[];
  coursePersonalVips: CoursePersonalVipsDto[];
  coursePersonalDetail: CoursePersonalDetailDto | null;
  coursePersonalClientListRes: CoursePersonalListRes | null;
  coursePersonalTeacherListRes: CoursePersonalListRes | null;
};

type Actions = {
  updateCoursePersonalNews: (data: CoursePersonalNewsDto[]) => void;
  updateCoursePersonalVips: (data: CoursePersonalVipsDto[]) => void;
  updateCoursePersonalDetail: (data: CoursePersonalDetailDto | null) => void;
  updateCoursePersonalClientList: (data: CoursePersonalListRes) => void;
  updateCoursePersonalTeacherList: (data: CoursePersonalListRes) => void;
};

const initState: State = {
  coursePersonalNews: [],
  coursePersonalVips: [],
  coursePersonalDetail: null,
  coursePersonalClientListRes: null,
  coursePersonalTeacherListRes: null,
};

const defaultActions: Actions = {
  updateCoursePersonalNews: () => { },
  updateCoursePersonalVips: () => { },
  updateCoursePersonalDetail: () => { },
  updateCoursePersonalClientList: () => { },
  updateCoursePersonalTeacherList: () => { },
};

const CoursePersonalContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const CoursePersonalProvider = ({ children }: PropsWithChildren<{}>) => {
  const [coursePersonalNews, setCoursePersonalNews] = useState<CoursePersonalNewsDto[]>([]);
  const [coursePersonalVips, setCoursePersonalVips] = useState<CoursePersonalVipsDto[]>([]);
  const [coursePersonalDetail, setCoursePersonalDetail] = useState<CoursePersonalDetailDto | null>(null);
  const [coursePersonalClientListRes, setCoursePersonalClientListRes] = useState<CoursePersonalListRes | null>(null);
  const [coursePersonalTeacherListRes, setCoursePersonalTeacherListRes] = useState<CoursePersonalListRes | null>(null);

  const updateCoursePersonalNews = (data: CoursePersonalNewsDto[]) => {
    setCoursePersonalNews(data);
  };

  const updateCoursePersonalVips = (data: CoursePersonalVipsDto[]) => {
    setCoursePersonalVips(data);
  };

  const updateCoursePersonalDetail = (data: CoursePersonalDetailDto | null) => {
    setCoursePersonalDetail(data);
  };

  const updateCoursePersonalClientList = (data: CoursePersonalListRes) => {
    setCoursePersonalClientListRes(data);
  };

  const updateCoursePersonalTeacherList = (data: CoursePersonalListRes) => {
    setCoursePersonalTeacherListRes(data);
  };

  return (
    <CoursePersonalContext.Provider
      value={{
        // Course Personal News
        coursePersonalNews,
        updateCoursePersonalNews,

        // Course Personal Vips
        coursePersonalVips,
        updateCoursePersonalVips,

        // Course Personal Detail
        coursePersonalDetail,
        updateCoursePersonalDetail,

        // Course Personal Client
        coursePersonalClientListRes,
        updateCoursePersonalClientList,

        // Course Personal Teacher
        coursePersonalTeacherListRes,
        updateCoursePersonalTeacherList,
      }}
    >
      {children}
    </CoursePersonalContext.Provider>
  );
};

export const useCoursePersonalContext = () => useContext(CoursePersonalContext);
