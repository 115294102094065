export const YOUTUBE = "https://www.youtube.com/@ninedev99";

export const LEVEL_DEV = [
  { label: "Fresher", value: "Fresher" },
  { label: "Junior", value: "Junior" },
  { label: "Middle", value: "Middle" },
  { label: "Senior", value: "Senior" },
];

export const STATUS_PUBLIC = [
  { label: "Private", value: "false" },
  { label: "Public", value: "true" },
];

export const RESULT_FRESHER = `
<div style="line-height: 1.6">
<div>Virtual DOM (DOM ảo) là một khái niệm trong React (và các thư viện JavaScript tương tự) để tối ưu hóa hiệu suất của việc cập nhật giao diện người dùng. Nó là một bản sao nhẹ của DOM thực (Real DOM) trong trình duyệt, giúp tăng tốc quá trình cập nhật và render giao diện.</div>

<h3>Lợi ích của Virtual DOM:</h3>
<ul>
<li>Tăng hiệu suất: Việc chỉ cập nhật những thay đổi cần thiết thay vì render lại toàn bộ giao diện giúp ứng dụng React nhanh hơn và hiệu quả hơn, đặc biệt là trong các ứng dụng phức tạp với nhiều phần tử.</li>

<li>Tránh tái render không cần thiết: Nhờ cơ chế so sánh và cập nhật chính xác, React có thể tránh việc tái render các phần tử không thay đổi.</li>

<li>Phát triển dễ dàng hơn: Với Virtual DOM, các nhà phát triển không cần phải quản lý việc cập nhật giao diện trực tiếp. React tự động xử lý việc này một cách thông minh và tối ưu.</li>
</ul>
</div>
`;

export const RESULT_JUNIOR = `
<div style="line-height: 1.6">
<div>
Higher-Order Component (HOC) là một mẫu thiết kế trong React cho phép bạn tái sử dụng logic giữa các component. HOC là một hàm nhận vào một component và trả về một component mới với các thuộc tính hoặc hành vi được mở rộng. Nói cách khác, nó là một “wrapper” quanh component ban đầu, giúp bổ sung thêm tính năng mà không cần sửa đổi component đó trực tiếp.
</div>

<h3>Đặc điểm của Higher-Order Component</h3>
<ul>
<li>HOC là một hàm nhận một component làm tham số và trả về một component mới với các tính năng hoặc logic mở rộng.</li>
<li>HOC không thay đổi component gốc mà thay vào đó tạo ra một component mới bao bọc component gốc.</li>
<li>HOC thường được dùng để chia sẻ hoặc tái sử dụng logic như: lấy dữ liệu, xử lý các sự kiện, quản lý quyền truy cập, và thao tác với state.</li>
</ul>

<h4>Tạo một HOC để kiểm tra quyền truy cập</h4>
<div style="background-color: #191B27; padding: 1rem; border-radius: 8px; font-family: Consolas, 'Courier New', monospace; font-size: 14px; line-height: 1.6;">
  <code style="color: #d4d4d4;">
    <span style="color: #569cd6;">import</span> React <span style="color: #569cd6;">from</span> <span style="color: #ce9178;">'react'</span>;<br /><br />

    <span style="color: #6a9955;">// HOC kiểm tra quyền truy cập</span><br />
    <span style="color: #569cd6;">function</span> <span style="color: #dcdcaa;">withAuthCheck</span>(<span style="color: #9cdcfe;">WrappedComponent</span>) {<br />
    &nbsp;&nbsp;<span style="color: #569cd6;">return</span> <span style="color: #569cd6;">function</span> <span style="color: #dcdcaa;">AuthComponent</span>(<span style="color: #9cdcfe;">props</span>) {<br />
    &nbsp;&nbsp;&nbsp;&nbsp;<span style="color: #569cd6;">const</span> <span style="color: #9cdcfe;">isAuthenticated</span> = <span style="color: #b5cea8;">true</span>; <span style="color: #6a9955;">// Giả sử người dùng đã đăng nhập</span><br />
    &nbsp;&nbsp;&nbsp;&nbsp;<span style="color: #569cd6;">if</span> (!isAuthenticated) {<br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color: #569cd6;">return</span> &lt;<span style="color: #d7ba7d;">h2</span>&gt;Please log in to continue&lt;/<span style="color: #d7ba7d;">h2</span>&gt;;<br />
    &nbsp;&nbsp;&nbsp;&nbsp;}<br />
    &nbsp;&nbsp;&nbsp;&nbsp;<span style="color: #569cd6;">return</span> &lt;<span style="color: #dcdcaa;">WrappedComponent</span> <span style="color: #9cdcfe;">{...props}</span> /&gt;;<br />
    &nbsp;&nbsp;}<br />
    }
  </code>
</div>

<h4>Sử dụng HOC trong component</h4>
<div style="background-color: #191B27; padding: 1rem; border-radius: 8px; font-family: Consolas, 'Courier New', monospace; font-size: 14px; line-height: 1.6;">
  <code style="color: #d4d4d4;">
    <span style="color: #569cd6;">function</span> <span style="color: #dcdcaa;">Dashboard</span>() {<br />
    &nbsp;&nbsp;<span style="color: #569cd6;">return</span> &lt;<span style="color: #d7ba7d;">h1</span>&gt;Welcome to your dashboard&lt;/<span style="color: #d7ba7d;">h1</span>&gt;;<br />
    }<br /><br />

    <span style="color: #6a9955;">// Tạo một component mới với quyền truy cập kiểm tra</span><br />
    <span style="color: #569cd6;">const</span> <span style="color: #9cdcfe;">ProtectedDashboard</span> = <span style="color: #dcdcaa;">withAuthCheck</span>(<span style="color: #dcdcaa;">Dashboard</span>);<br /><br />

    <span style="color: #569cd6;">export default</span> <span style="color: #9cdcfe;">ProtectedDashboard</span>;
  </code>
</div>

</div>
`;

export const RESULT_SENIOR = `
<div style="line-height: 1.6">
<div><strong>Reconciliation trong React</strong> là quá trình so sánh giữa Virtual DOM và actual DOM để xác định những thay đổi cần thực hiện khi state hoặc props của component thay đổi. Mục tiêu của reconciliation là tối ưu hóa quá trình render, đảm bảo rằng React chỉ thay đổi những phần cần thiết của giao diện người dùng, giảm thiểu thao tác với DOM thực tế (actual DOM), và từ đó cải thiện hiệu suất của ứng dụng.</div>

<h3>Lợi ích của Reconciliation:</h3>
<ul>
<li><strong>Tối ưu hóa hiệu suất:</strong> Reconciliation giúp React chỉ thay đổi những phần của giao diện mà thực sự thay đổi, thay vì phải re-render lại toàn bộ ứng dụng. Điều này giúp giảm chi phí và nâng cao hiệu suất.</li>
<li><strong>Giảm thao tác DOM:</strong> Thao tác trực tiếp với DOM thực tế rất đắt đỏ về mặt hiệu suất. Với Virtual DOM và Reconciliation, React có thể tránh thao tác DOM không cần thiết.</li>
</ul>

<h3>Các kỹ thuật hỗ trợ Reconciliation:</h3>
<ul>
<li><strong>PureComponent:</strong> Giúp giảm số lần render không cần thiết bằng cách thực hiện so sánh nông props và state. Nếu không có sự thay đổi, component sẽ không render lại.</li>
<li><strong>React.memo():</strong> Đối với các functional component, React.memo() giúp tránh việc render lại component khi props không thay đổi.</li>
<li><strong>Keys trong danh sách:</strong> Sử dụng key khi render danh sách phần tử giúp React dễ dàng nhận diện các phần tử thay đổi và tái sử dụng chúng một cách hiệu quả.</li>
</ul>
</div>
`;

export const [DARK_THEME, LIGHT_THEME] = ['dark', 'light'];
export const [WHITE, PURPLE_01] = ['#fff', '#6c40c9'];
