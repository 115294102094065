import { Theme } from '@mui/material/styles';
import { StorageKey } from 'globals/constants/storage';
import { DARK_THEME, LIGHT_THEME } from 'globals/enums/common.enum';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useState
} from 'react';
import { getStorage, setStorage } from 'services/storageService';
import { colorDark, colorLight } from 'theme/mode';
import customTheme from 'theme/theme';

type State = {
  theme: Theme;
  activeTheme: string;
};

type Actions = {
  updateTheme: (theme: Theme, activeTheme: string) => void;
};

const initState: State = {
  theme: getStorage(StorageKey.ThemeColor) == LIGHT_THEME ? customTheme(colorLight) : customTheme(colorDark),
  activeTheme: getStorage(StorageKey.ThemeColor) == LIGHT_THEME ? LIGHT_THEME : DARK_THEME,
};

const defaultActions: Actions = {
  updateTheme: () => { }
};

const ThemeContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const ThemeCustomizeProvider = ({ children }: PropsWithChildren<{}>) => {
  const [theme, setTheme] = useState(initState.theme);
  const [activeTheme, setActiveTheme] = useState(initState.activeTheme);

  const updateTheme = (theme: Theme, activeTheme: string) => {
    setTheme(theme);
    setActiveTheme(activeTheme);
    setStorage(StorageKey.ThemeColor, activeTheme);
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        activeTheme,
        updateTheme
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
