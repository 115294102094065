import BaseModel from "./base.class";

export class QuestionDto extends BaseModel<QuestionDto> {
  id?: number;
  name?: string;
  level?: string;
  view?: number;
  interviewCategoryId?: number;
}

export type QuestionListRes = {
  list: QuestionDto[];
  total: number;
  page: number;
  pageSize: number;
};

export type QuestionData = {
  name: string;
  level: string;
  interviewCategoryId: number;
};

export type QuestionSearchType = {
  searchName: string;
  selectedLevel: string;
};
