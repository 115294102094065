import React, { useEffect, useState } from "react";
import styled from "styled-components";

// HOOKS
import { useTranslation } from "react-i18next";
import useLoadingCallback from "hooks/useLoadingCallback";
import { useSnackbarContext } from "store/snackbarContext";
import { useAuthContext } from "apis/authApis/authContext";
import useRoute from "hooks/useRoute";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import TextField from "components/formControls/TextField";
import Button from "components/formControls/Button";
import { PageRouteName } from "globals/enums/routes.enum";
import { useRouteContext } from "store/routeContext";
import { actionCourseCommentCreateApi, actionCourseCommentListApi } from "apis/courseCommentApis/courseCommentAction";
import { CourseCommentByCoursePersonalIdDto } from "models/classes/courseComment.class";
import { isEmpty } from "lodash";

type Props = {
  coursePersonalId: number;
}

const CourseComment = React.memo(({
  coursePersonalId
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { redirect, pathname } = useRoute();

  // states
  const [txtComment, setTxtComment] = useState('');

  // hooks
  const { updateSnackbar } = useSnackbarContext();
  const { token } = useAuthContext();
  const { updatePrevRoute } = useRouteContext();

  const [commentList, setCommentList] = useState<CourseCommentByCoursePersonalIdDto[]>([]);

  const fetchCommentList = async () => {
    try {
      const data = await actionCourseCommentListApi(coursePersonalId);
      setCommentList(data);
    } catch (error) {
      console.log('fetchCommentList', error);
    }
  };

  const [handleComment, isLoadingComment] = useLoadingCallback(async () => {
    if (!txtComment) {
      updateSnackbar({ type: 'error', message: 'Enter your comment course', open: true });
      return
    } else if (!token) {
      updatePrevRoute(pathname);
      redirect(PageRouteName.SignInPage);
      return;
    }
    const data = await actionCourseCommentCreateApi({
      coursePersonalId,
      name: txtComment,
    });
    if (data) {
      updateSnackbar({ type: 'success', message: 'Create success', open: true });
      setTxtComment('');
      fetchCommentList();
    } else {
      updateSnackbar({ type: 'error', message: 'Create fail', open: true });
    }
  });

  // effects
  useEffect(() => {
    fetchCommentList();
  }, []);

  return (
    <WrapperStyled
      borderRadius={3}
      flexDirection={{ xs: 'column', md: 'row' }}
      rowGap={{ xs: '1rem' }}
      columnGap={{ xs: '1rem' }}
    >
      <Stack
        padding={{ xs: '2rem 2rem' }}
        bgcolor={theme.palette.primary.light}
        width={{ xs: '-webkit-fill-available' }}
        height={{ xs: '100%' }}
        borderRadius={3}
        flexDirection={{ xs: 'column' }}
        rowGap={{ xs: '1rem' }}
      >
        <Box
          fontSize={{ xs: 16, md: 20 }}
          fontWeight={500}
          textTransform="uppercase"
          color={theme.palette.text.light}
        >
          {t('Comment course')}
        </Box>
        <TextField
          multiline
          maxLength={250}
          name="content"
          placeholder={t('Enter your comment course')}
          background={theme.palette.default.light}
          style={{ width: '100%' }}
          value={txtComment || ''}
          onChange={(e) => setTxtComment(e.target.value)}
          required
        />
        <Button
          style={{ fontWeight: 300 }}
          disabled={isLoadingComment}
          onClick={handleComment}
        >
          {t('Confirm')}
        </Button>
        <Box fontStyle="italic" fontSize={12} color={theme.palette.text.dark} fontWeight={300}>
          {t('Note: Non-contributory responses will not be displayed')}
        </Box>
      </Stack>
      <Stack
        padding={{ xs: '2rem 2rem' }}
        bgcolor={theme.palette.primary.light}
        width={{ xs: '-webkit-fill-available' }}
        height={{ xs: '100%' }}
        borderRadius={3}
        flexDirection={{ xs: 'column' }}
        rowGap={{ xs: '1rem' }}
      >
        <Box
          fontSize={{ xs: 16, md: 20 }}
          fontWeight={500}
          textTransform="uppercase"
          color={theme.palette.text.light}
        >
          Feedback
        </Box>
        <Stack
          flexDirection={{ xs: 'column' }}
          rowGap={{ xs: '1rem' }}
          height={180}
          overflow="auto"
          className="scroll-roadmap"
        >
          {(commentList || []).map(item => (
            <Stack
              key={item.id}
              flexDirection={{ xs: 'column' }}
              rowGap={{ xs: '.25rem' }}
              bgcolor={theme.palette.primary.dark}
              padding={{ xs: '1rem' }}
              borderRadius={1}
            >
              <Stack
                flexDirection={{ xs: 'row' }}
                justifyContent={{ xs: 'space-between' }}
                alignItems={{ xs: 'center' }}
              >
                <Box color={theme.palette.text.light} fontSize={14} flex={1.5}>{item.name}</Box>
                <Box color={theme.palette.text.dark} fontSize={12} flex={0.5} textAlign="end">{item.nameUser}</Box>
              </Stack>
            </Stack>
          ))}
          {isEmpty(commentList) && (
            <Stack
              flexDirection={{ xs: 'row' }}
              justifyContent={{ xs: 'center' }}
              alignItems={{ xs: 'center' }}
              height={{ xs: '100%' }}
              color={theme.palette.text.light}
              fontStyle="italic"
              fontWeight={300}
              fontSize={13}
            >
              {t('No comments for this course yet')}
            </Stack>
          )}
        </Stack>
      </Stack>
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`
`;

export default CourseComment;