import BaseModel from "./base.class";

export class InterviewCategoryDto extends BaseModel<InterviewCategoryDto> {
  id?: number;
  name?: string;
  image?: string;
  numberOfQuestions?: number;
}

export type InterviewCategoryListRes = {
  list: InterviewCategoryDto[];
  total: number;
  page: number;
  pageSize: number;
};

export type InterviewCategoryData = {
  name: string;
  image: string;
};