import { CoursePersonalData } from "models/classes/coursePersonal.class";
import { parseRequestParams } from "utils/dataMappers";
import { publicRequest } from "../configs/requestApis";
import { FetchCoursePersonalParams } from "./coursePersonalParam";

export const fetchCoursePersonalNewsApi = async ({
  newAbortSignal,
  ...params
}: FetchCoursePersonalParams) =>
  publicRequest.get(
    `/course/personal/client/list/news`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchCoursePersonalVipsApi = async ({
  newAbortSignal,
  ...params
}: FetchCoursePersonalParams) =>
  publicRequest.get(
    `/course/personal/client/list/vips`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchCoursePersonalDetailApi = async ({
  newAbortSignal,
  coursePersonalId,
  ...params
}: FetchCoursePersonalParams) =>
  publicRequest.get(
    `/course/personal/client/detail/${coursePersonalId}`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchCoursePersonalClientListApi = async ({
  searchName,
  page,
  pageSize,
  newAbortSignal,
  ...params
}: FetchCoursePersonalParams) =>
  publicRequest.get(
    `/course/personal/client/list`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchCoursePersonalTeacherListApi = async ({
  searchName,
  page,
  pageSize,
  newAbortSignal,
  ...params
}: FetchCoursePersonalParams) =>
  publicRequest.get(
    `/course/personal/teacher/list`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchCoursePersonalCreateApi = async (body: CoursePersonalData) =>
  publicRequest.post(`/course/personal/create`, body) as any;

export const fetchCoursePersonalUpdateApi = async (
  id: number,
  body: CoursePersonalData
) => publicRequest.put(`/course/personal/update/${id}`, body) as any;

export const fetchCourseUpdateFreeApi = async (id: number) =>
  publicRequest.put(`/course/personal/update/free/${id}`);

export const fetchCourseDetailPublicApi = async ({
  newAbortSignal,
  coursePersonalId,
  ...params
}: FetchCoursePersonalParams) =>
  publicRequest.get(
    `/course/personal/detail/${coursePersonalId}`,
    parseRequestParams(params, { newAbortSignal }, true)
  );

export const fetchCoursePersonalUpdateByAdminApi = async (
  id: number,
  body: CoursePersonalData
) => publicRequest.put(`/course/personal/admin/update/${id}`, body) as any;
