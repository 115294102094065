import { lazy, Suspense } from "react";
import styled from "styled-components";

// ICONS
import CharacterBanner from 'assets/banner-khoahoc.webp';

// HOOKS
import useRoute from "hooks/useRoute";
import { useSearchParams } from "react-router-dom";

// COMPONENTS
import { Stack } from "@mui/material";
import { useInterviewClientByApi } from "apis/interviewCategoryApis/useInterviewCategoryApis";
import { PageRouteName } from "globals/enums/routes.enum";

// CONSTANTS

// LAZY
const BannerLayout = lazy(() => import("layouts/BannerLayout"));
const InterviewList = lazy(() => import("./sections/InterviewList"));
const InterviewSearch = lazy(() => import("./sections/InterviewSearch"));

const InterviewPage = () => {
  // instances
  const { redirect } = useRoute();
  const [searchParams] = useSearchParams();
  const searchName = searchParams.get('name') ? String(searchParams.get('name')) : '';
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { interviewClientListRes } = useInterviewClientByApi({
    searchName,
    page,
  });

  return (
    <WrapperStyled
      padding={{ xs: '3rem 2rem' }}
      width={{ xs: 'auto', md: '100%' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '2rem' }}
    >
      <Suspense>
        <BannerLayout
          height={{ xs: '10rem', md: '12rem' }}
          title="Interview"
          subTitle="Choose your favorite interview!"
          imageFile={CharacterBanner}
          widthImage={{ xs: '150px', md: '200px' }}
          heightImage={{ xs: '150px' }}
        />
        <InterviewSearch
          title="Search interview"
          searchName={searchName}
          onSubmitSearch={(searchName: string) =>
            redirect(PageRouteName.InterviewPage, { page: 1, name: searchName })
          }
        />
        <InterviewList
          interviewListRes={interviewClientListRes}
          page={page}
          setPage={(page) => redirect(PageRouteName.InterviewPage, { page, name: searchName })}
        />
      </Suspense>
    </WrapperStyled>
  );
};

const WrapperStyled = styled(Stack)`
`;

export default InterviewPage;
