import styled from "styled-components";

// HOOKS
import { useSearchParams } from "react-router-dom";

// ICONS

// COMPONENTS
import { Stack } from "@mui/material";
import CourseViewLesson from "./sections/CourseViewLesson";
import CourseViewList from "./sections/CourseViewList";
import { useCourseLessonsByApi, useLessonDetailByApi } from "apis/courseLessonApis/useCourseLessonApis";

const CourseViewPage = () => {
  // instances
  const [searchParams] = useSearchParams();
  const coursePersonalId = searchParams.get('coursePersonalId') ? Number(searchParams.get('coursePersonalId')) : 0;
  const courseLessonId = searchParams.get('courseLessonId') ? Number(searchParams.get('courseLessonId')) : 0;

  // hooks
  const { lessons } = useCourseLessonsByApi({ coursePersonalId });
  const { lesson } = useLessonDetailByApi({ coursePersonalId, courseLessonId });

  return (
    <WrapperStyled
      padding={{ xs: '3rem 2rem' }}
      width={{ xs: 'auto', md: '100%' }}
      flexDirection={{ xs: 'column', md: 'row' }}
      rowGap={{ xs: '2rem' }}
      columnGap={{ xs: '2rem' }}
    >
      <CourseViewLesson lesson={lesson} />
      <CourseViewList
        lessons={lessons}
        courseLessonId={courseLessonId}
        coursePersonalId={coursePersonalId}
      />
    </WrapperStyled>
  )
};

const WrapperStyled = styled(Stack)`
`;

export default CourseViewPage;
