
import { useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Button from 'components/formControls/Button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type Props = {
  label?: string;
  open: boolean;
  isClose?: boolean;
  onClose: (value: boolean) => void;
  isConfirm?: boolean;
  onConfirm: () => void;
  children: React.ReactNode;
}

const DialogSlide = ({ label, open, isClose = true, onClose, isConfirm = true, onConfirm, children }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleClose = () => {
    onClose(false);
  };

  return (
    <WrapperStyled
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="sm"
      onClose={handleClose}
    >
      {label && (
        <DialogTitle
          sx={{ color: theme.palette.text.light }}
          fontWeight={500} fontFamily={`"Montserrat", sans-serif`}
        >
          {t(label)}
        </DialogTitle>
      )}
      <DialogContent>
        {children}
      </DialogContent>
      {(isClose || isConfirm) && (
        <DialogActions sx={{ padding: '0 1.5rem 1.5rem' }}>
          {isClose && (
            <Button onClick={handleClose} color="success" width="120px" style={{ fontWeight: 300 }}>{t('Disagree')}</Button>
          )}
          {isConfirm && (
            <Button onClick={onConfirm} width="120px" style={{ fontWeight: 300 }}>{t('Agree')}</Button>
          )}
        </DialogActions>
      )}
    </WrapperStyled>
  );
};

const WrapperStyled = styled(Dialog)`

  .MuiPaper-root {
    background-color: ${p => p.theme.palette.primary.light};
    box-shadow: none;
    background-image: none;
  }

`;

export default DialogSlide;
